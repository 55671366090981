import React from 'react';
import { graphql } from 'gatsby';
import { PageBuilderContent } from '@Omegapoint/components/pageBuilderComponents';
import DefaultLayout from '@Layouts/Default';

const HomePage = (props) => {
  const { data } = props;
  //const pageData = data.umbraco.umbraco.content.byId;
  return (
    <DefaultLayout
      path={props.path}
      culture={props.pageContext.culture}
      title={
        data?.cmsPages?.MetaTitle
          ? data?.cmsPages?.MetaTitle
          : data?.cmsPages?.PageTitle
      }
      description={data?.cmsPages?.MetaDescription}
      secondaryNavigation={data.cmsPages.SecondaryNavigation}
      requireLogin={data?.cmsPages?.RequireLogin}
    >
      <PageBuilderContent
        content={data.cmsPages.Items.Content}
      ></PageBuilderContent>
    </DefaultLayout>
  );
};

export default HomePage;

export const query = graphql`
  query HomePageQuery($id: String!) {
    cmsPages(id: { eq: $id }) {
      Name
      MetaTitle
      PageTitle
      MetaDescription
      Url
      id
      Type
      SecondaryNavigation
      RequireLogin
      Items {
        Content {
          ...PageBuilderContent
        }
      }
    }
  }
`;
